const colors = {
    colorPrimary: '#067966',
    colorPrimaryDark: '#004D40',
    backgroundColor: '#F0EEEE',
    red: '#FF4444',
    white: '#FFFFFFFF',
    black: '#000000',
    black_light: '#363636',
    grey: '#B5B5B5',
    test: '#FFFFFFFF',
    yellow: '#FFFF00'
    // tambahkan warna lainnya sesuai kebutuhan
  };
  
  export default colors;