// TextInputEditText.js

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const MaterialEditText1 = ({
  label,
  maxLength,
  style,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <TextField
      label={label}
      inputProps={{ maxLength: maxLength }}
      style={style}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

const MaterialEditText2 = ({
    label,
    placeholder,
    value,
    onChange,
  }) => {
    return (
      <TextField
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    );
  };

MaterialEditText1.propTypes = {
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

MaterialEditText2.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export {MaterialEditText1, MaterialEditText2};
