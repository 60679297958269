import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MaterialButton from '@mui/material/Button';
import { MaterialEditText1 } from './WIDGET/MaterialEditText.js';
import './App.css';
import './GlobalCss.css';
import api from './API.js';
import Session from './Session.js';
import colors from './Style_Color/COLOR.js';
import AppBarLayout from './WIDGET/AppBarLayout.js';

function App() {
  const navigate = useNavigate();
  const [nomorHp, setNomorHp] = useState('');
  const [nik, setNik] = useState('');

  const onClickListener = async () => {
    if (!nomorHp || !nik) {
      console.log('Isi semua field sebelum melanjutkan.');
      alert(`Isi semua field sebelum melanjutkan.`);
      return;
    }

    try {
      const response = await fetch(api.getDataPasienSecondary, {
        method: 'POST',
        body: JSON.stringify({nohp: nomorHp, nik: nik,}),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        const dataPasien = responseData.response.data_pasien;
        const code = responseData.metadata.code;
        const message = responseData.metadata.message;
  
        console.log('Data Pasien:', response);
        console.log('Data Pasien:', code);
        console.log('Data Pasien:', '' + message);

        if(code === "200"){
          sendOtp({
            nama: dataPasien.nama,
            pasien_id: dataPasien.pasien_id,
            nomorHp: dataPasien.telp,
            address: dataPasien.alamat,
            noRm: dataPasien.rm,
          });
        } else alert(`Data tidak ditemukan untuk nomor ` + nomorHp + " dengan NIK " + nik);
      } else {
        const errorData = await response.json();
        alert(`Response: ${errorData.metadata.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async function sendOtp(state) {
    try {
      const response = await fetch(api.postMobileSettingOtpSecondary, {
        method: 'POST',
        body: JSON.stringify({
          pasien_id: state?.pasien_id,
          nomor_hp: state?.nomorHp,
        }),
      });
  
      if (response.status === 200) {
        // Konfirmasi sukses, lakukan apa yang diperlukan, misalnya pindah ke halaman berikutnya
        console.log('pasien_id: ' + state?.pasien_id);
        console.log('nomor_hp: ' + state?.nomorHp);
        console.log('address: ' + state?.address);
        console.log('Konfirmasi OTP berhasil');
  
        // Pindahkan blok navigate ke sini
          
          Session.setItem("nama", state?.nama);
          Session.setItem("pasien_id", state?.pasien_id);
          Session.setItem("nomorHp", state?.nomorHp);
          Session.setItem("address", state?.address);
          Session.setItem("noRm", state?.noRm);

          console.log('Data Pasien nama:', sessionStorage.getItem('nama'));
          console.log('Data Pasien nomorHp:', sessionStorage.getItem('nomorHp'));
          console.log('Data Pasien noRm:', sessionStorage.getItem('noRm'));
          console.log('Data Pasien pasien_id:', sessionStorage.getItem('pasien_id'));
          console.log('Data Pasien address:', sessionStorage.getItem('address'));
        navigate('/form_otp', {
          state: {
            nama: state?.nama,
            nomorHp: state?.nomorHp,
            noRm: state?.noRm,
            pasien_id: state?.pasien_id,
            address: state?.address,
          },
        });
      } else {
        const errorData = await response.json();
        console.error('Konfirmasi OTP gagal:', errorData.metadata.message);
      }
    } catch (error) {
      console.error('Error pada saat mengirim OTP:', error);
    }
  }
  
  // Variable Declaration CSS
  const aa = {marginTop:16, paddingLeft:8, paddingRight: 8,  display: 'flex', flexDirection: 'column', alignItems: 'center'};
  const CardView = {padding: 16,maxWidth: '80%',backgroundColor: colors.white, 
    flexDirection: 'column',alignItems: 'center',borderRadius: 8,
    border: '1px solid #ccc',boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)'};
  const AppCompatTextView = { fontSize:24, color: colors.colorPrimaryDark, userSelect: 'none', textAlign:'center', marginTop:16 };
  const AppCompatTextView1 = { width: '100%', fontSize: 11, color: colors.colorPrimaryDark, userSelect: 'none', textAlign: 'center'};
  const AppCompatTextView2 = { width: '100%', fontSize: 14, color: colors.colorPrimaryDark, userSelect: 'none', textAlign: 'center', marginTop: 16 };
  const EditTextTop = {marginBottom:8,fontSize: 12,width: '100%', marginTop: 16,
      minHeight: '30px',backgroundColor: colors.backgroundColor};
  const EditTextBottom = {marginBottom:8,fontSize: 12,width: '100%', marginTop: 4, 
      minHeight: '40px',backgroundColor: colors.backgroundColor};
  const MaterialButtonStyle = {fontSize:14, maxWidth:220,minWidth:'200',width: '96%', height:33, color:colors.white, marginLeft: 'auto', marginRight: 'auto', 
      marginTop:16, backgroundColor: colors.colorPrimary,};

  return (
     <div className="Root" style={{paddingTop:8,paddingLeft:4,margin:0,alignContent:'center', height: '100%'}}>
      <AppBarLayout/>

      <main style={{padding:16}}>
          <h2 style={AppCompatTextView}>Selamat datang di Website <br/> Pendaftaran Online RSIY PDHI</h2>

      {/* Cardview disini */}
        <div style={aa}>
          <div style={CardView}>

              <p style={{AppCompatTextView1}}>Silahkan masukkan Nomor ponsel dan NIK anda <br/>untuk melanjutkan</p>

              <MaterialEditText1 label="Nomor Hp"
                  maxLength={20}
                  style={EditTextTop}
                  placeholder="Masukkan Nomor Hp"
                  value={nomorHp}
                  onChange={(e) => setNomorHp(e.target.value.replace(/[^0-9]/g, ''))}
                />

              <MaterialEditText1 label="Nomor NIK"
                  maxLength={17}
                  style={EditTextBottom}
                  placeholder="Masukkan Nomor NIK"
                  value={nik}
                  onChange={(e) => setNik(e.target.value.replace(/[^0-9]/g, ''))}
                />

            <p style={AppCompatTextView2}>Belum memiliki akun? 
                <Link to="/FORM_REGISTRATION" style={{ color: 'blue', textDecoration: 'underline' }}>Klik disini</Link> 
                <br/>untuk mendaftar sebagai pasien baru
            </p>

            <div style={{textAlign: 'center', width:'100%', display: 'flex',
             justifyContent: 'space-between', userSelect: 'none', backgroundColor: colors.white}}>
              {/* <button className="Button" style={{ ...MaterialButton, ...MaterialButtonHover, ...MaterialButtonActive }}  onClick={onClickListener}>
                  Cari
              </button> */}

              <MaterialButton variant="contained" style={MaterialButtonStyle} onClick={onClickListener}>
                Cari
              </MaterialButton>
            </div>
            
          </div>
        </div>
      {/* Akhir Cardview */}

      </main>
     </div>
  );
}

export default App;
