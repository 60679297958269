import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import MaterialButton from '@mui/material/Button';
import '../App.css';
import '../GlobalCss.css';
import api from '../API.js';
import colors from '../Style_Color/COLOR.js';
import AppBarLayout from '../WIDGET/AppBarLayout.js';

function FORM_OTP() {
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();

    const [countdown, setCountdown] = useState(300); 
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [enteredOTP, setEnteredOTP] = useState('');

    useEffect(() => {
      const intervalId = setInterval(() => {setCountdown((value) => (value > 0 ? value - 1 : 0));}, 1000);
      return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {if(countdown === 0) setIsButtonEnabled(false);}, [countdown]);
      
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    const onClickRightListener = async () => {
      if (!enteredOTP) {
          console.log('Isi kode OTP sebelum melanjutkan.');
          alert(`Isi kode OTP sebelum melanjutkan.`);
          return;
      }
  
      try {
          const response = await fetch(api.checkOTPSecondary, {
              method: 'POST',
              body: JSON.stringify({
                  pasien_id: sessionStorage.getItem('pasien_id'),
                  code_otp: enteredOTP,
              }),
          });
  
          if (response.status === 200) {
              const responseData = await response.json();
              const code = responseData.metadata.code;
              const message = responseData.metadata.message;
              console.log('Data Otp:', code);
              console.log('Data Otp:', message);
              console.log('address:2', sessionStorage.getItem('address'));
          
              console.log('Data Pasien nama:', sessionStorage.getItem('nama'));
              console.log('Data Pasien nomorHp:', sessionStorage.getItem('nomorHp'));
              console.log('Data Pasien noRm:', sessionStorage.getItem('noRm'));
              console.log('Data Pasien pasien_id:', sessionStorage.getItem('pasien_id'));
              console.log('Data Pasien address:', sessionStorage.getItem('address'));
              if(code === "200"){
                navigate('/form_poli', {
                  state: {
                    nama: sessionStorage.getItem('nama'),
                    nomorHp: sessionStorage.getItem('nomorHp'),
                    noRm: sessionStorage.getItem('noRm'),
                    pasien_id: sessionStorage.getItem('pasien_id'),
                    address: sessionStorage.getItem('address'),
                  },
                });
              } else {
                console.error('Konfirmasi OTP gagal:', message);
                alert(`Data OTP tidak valid atau mungkin sudah kadaluarsa.`);
              }
          } else {
              // Konfirmasi gagal, tangani kesalahan jika status kode HTTP bukan 200
              const errorData = await response.json();
              console.error('Konfirmasi OTP gagal:', errorData.metadata.message);
          }
      } catch (error) {
          //console.error('Error:', error);
      }
  };

    const onClickLeftListener = async () => {

      try {
          const response = await fetch(api.postMobileSettingOtpSecondary, {
              method: 'POST',
              body: JSON.stringify({
                  pasien_id: state?.pasien_id,
                  nomor_hp: state?.nomorHp,
              }),
          });

          if (response.status === 200) {
              // Konfirmasi sukses, lakukan apa yang diperlukan, misalnya pindah ke halaman berikutnya
              console.log('pasien_id: ' + state?.pasien_id);
              console.log('nomor_hp: ' + state?.nomorHp);
              console.log('Konfirmasi OTP berhasil');
              setCountdown(300);
              // Redirect ke halaman berikutnya
              // ...

          } else {
              // Konfirmasi gagal, tangani kesalahan jika status kode HTTP bukan 200
              const errorData = await response.json();
              console.error('Konfirmasi OTP gagal:', errorData.metadata.message);
              alert(`Konfirmasi OTP gagal: ${errorData.metadata.message}`);
              // Tampilkan pesan kesalahan atau lakukan tindakan sesuai kebutuhan
          }
      } catch (error) {
          console.error('Error:', error);
      }
  };
  

    // Variable Declaration CSS
    const aa = {marginTop:12, minWidth:'200',width: '90%', paddingLeft:16, paddingRight:16,display:'flex', flexDirection: 'column', alignItems: 'center'};
    const CardView = {marginLeft:16, marginRight:16,minWidth:'300px',maxWidth:'70%',backgroundColor: colors.white, 
        display: 'flex', flexDirection: 'column',alignItems: 'center',borderRadius: 8, userSelect: 'none', 
        border: '1px solid #ccc',boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)'};
    const EditText = {marginTop: 16,fontSize: 12, minWidth: '150',width: '100%',textAlign: 'center',
        border: '1px solid #ccc',borderRadius: 4,minHeight: '40px',backgroundColor: colors.backgroundColor};
    const MaterialButton1 = {fontSize:10, width:'45%', height:28, backgroundColor: countdown === 0 ? colors.colorPrimary : colors.grey, color:countdown === 0 ? 'white' : 'grey', marginLeft: 'auto', marginRight: 'auto', marginTop:32};
    const MaterialButton2 = {fontSize:10, width:'45%', height:28, backgroundColor: isButtonEnabled ? colors.colorPrimary : colors.grey, color:isButtonEnabled ? colors.white : 'grey', marginLeft: 'auto', marginRight: 'auto', marginTop:32};
  
  return (
    <div className="Root" style={{paddingTop:8,paddingLeft:4,margin:0,alignContent:'center'}}>
      <AppBarLayout/>
      <main style={{paddingBottom:50,width:'100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h1 style={{fontSize:22, width:'100%', color: colors.colorPrimaryDark, userSelect: 'none', textAlign:'center', marginTop:16 }}>
          Masukkan kode OTP<br/>yang telah dikirimkan<br/>ke nomor {state.nomorHp}
          </h1>

      {/* Cardview disini */}

        <div style={aa}>
          <div style={CardView} >
            <h1 style={{fontSize:20, color: colors.black, userSelect: 'none', textAlign:'center', marginTop:16 }}>
            {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
            </h1>
            <p style={{fontSize:12, color: colors.black_light, userSelect: 'none', textAlign:'center', marginTop:2 }}>
            Tidak mendapatkan kode OTP?<br/>Silahkan klik tombol kirim ulang
            </p>
            <div>
              <input
                  type="tel"
                  className="EdittextOtp"
                  placeholder="Masukkan kode OTP"
                  maxLength={4}
                  style={EditText}
                  aria-label="Masukkan kode OTP"
                  value={enteredOTP}
                  onChange={(e) => {
                    const value = e.target.value;
                    const inputOTP = value.replace(/[^0-9]/g, '').substring(0, 4);
                    if (inputOTP.length === 4) {
                      setIsButtonEnabled(true);
                      console.log(`total length inputOtp top: ${inputOTP.length}`);
                      console.log(`button status: ${isButtonEnabled}`);
                    } else {
                      setIsButtonEnabled(false);
                      console.log(`total length inputOtp bottom: ${inputOTP.length}`);
                      console.log(`button status: ${isButtonEnabled}`);
                    }
                    setEnteredOTP(inputOTP);
                  }}
              />
            </div>
            
            <div style={{width:'80%', marginBottom:16, display: 'flex',
             justifyContent: 'space-between', userSelect: 'none'}}>

              <MaterialButton variant="contained" className="Button_left" style={MaterialButton1}
                onClick={onClickLeftListener} disabled={countdown !== 0}>
                Kirim Ulang
              </MaterialButton>

              <MaterialButton variant="contained" className="Button_right" style={MaterialButton2}
                onClick={onClickRightListener} disabled={!isButtonEnabled}>
                Konfirmasi
              </MaterialButton>

            </div>
            
          </div>
        </div>


      <p style={{fontSize:12, color: colors.black_light, userSelect: 'none', textAlign:'center', marginTop:16 }}>
            Kode OTP telah dikirim!
      </p>

      </main>

    </div>
  );
}

export default FORM_OTP;
