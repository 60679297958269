import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';
import '../GlobalCss.css';
import '../FORM_POLI/Form_Poli.css';
import MaterialButton from '@mui/material/Button';
import { MaterialEditText1 } from '../WIDGET/MaterialEditText.js';
import TabLayout from './TabLayout.js';
import colors from '../Style_Color/COLOR.js';
import AppBarLayout from '../WIDGET/AppBarLayout.js';
import API from '../API.js';
import ARRAY from '../ARRAY.js';
import Session from '../Session.js';

const currentYear = new Date().getFullYear();
const daysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

const range = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
};

function Form_Registration() {
  const navigate = useNavigate();
  const propList = ARRAY.PROVINSI;
  const [pekerjaanList, setPekerjaanList] = useState([]);
  const [kabList, setKabList] = useState([]);
  const [kecList, setKecList] = useState([]);
  const [kelList, setKelList] = useState([]);
  const [selectedGender, setSelectedGender] = useState(1);
  const [selectedReligion, setSelectedReligion] = useState('ISLAM');
  const [selectedPekerjaan, setSelectedPekerjaan] = useState('');
  const [selectedProp, setSelectedProp] = useState('PV01');
  const [selectedKab, setSelectedKab] = useState('');
  const [selectedKec, setSelectedKec] = useState('');
  const [selectedKel, setSelectedKel] = useState('');
  const [selectedPropId, setPropId] = useState('PV01');
  const [selectedKabId, setKabId] = useState('');
  const [selectedKecId, setKecId] = useState('');
  const [selectedKelId, setKelId] = useState('');
  const [user_name, setUserName] = useState('');
  const [user_nik, setUserNik] = useState('');
  let [user_phone, setUserPhone] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [user_tempat_lahir, setUserTempatLahir] = useState('');
  const [user_address, setUserAddress] = useState('');

  const [selectedMonthNumber, setSelectedMonthNumber] = useState(0);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [years, setYears] = useState([]);
  useEffect(() => {
    const startYear = 1945;
    const yearArray = [];

    for (let year = startYear; year <= currentYear; year++) yearArray.push(year);
    setYears(yearArray);
  }, []);
  const [selectedDay, setSelectedDay] = useState('1');
  const days = range(1, daysInMonth(selectedMonthNumber, selectedYear));
  useEffect(() => {
    const updatedDays = range(1, daysInMonth(selectedMonthNumber, selectedYear));
    setSelectedDay(updatedDays[0]); // Pilih tanggal pertama setiap kali bulan atau tahun berubah
  }, [selectedMonthNumber, selectedYear]);

  useEffect(() => {
    const getDataPekerjaan = async () => {
      try {
        const response = await fetch(API.getPekerjaan);
        const data = await response.json();
        const Response = data.response;
        const pekerjaan = Response.pekerjaan;
        if (data && Response && pekerjaan) setPekerjaanList(pekerjaan);
        const array_data = pekerjaan[0]?.pekerjaan_name;
        console.log("getDataPekerjaan:", array_data);
        setSelectedPekerjaan(array_data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getDataPekerjaan();
  }, []);
  const fetchKabData = async (selectedProp) => {
    try {
      const response = await fetch(API.getKab, {
        method: 'POST',
        body: JSON.stringify({ prop_id: selectedProp }),
      });
  
      const data = await response.json();
      if (data && data.response && data.response.kab) {
        const modifiedKabList = data.response.kab.map((kab) => ({
          kab_id: kab.kab_id,
          kab_name: kab.kab_name,
        }));

        const array = data.response.kab;
        const array_data = array[0]?.kab_id;
        console.log("array pertama:", array_data);
        setKabList(modifiedKabList);
        fetchKecData(array_data);
      }
    } catch (error) {
      console.error('Error fetching kabupaten data:', error);
    }
  };
  useEffect(() => {fetchKabData(selectedProp);}, [selectedProp]);
  
  const fetchKecData = async (selectedKab) => {
    try {
      console.log("fetchKecData:", selectedKab);
      const response = await fetch(API.getKec, {
        method: 'POST',
        body: JSON.stringify({ kab_id: selectedKab }),
      });
  
      const data = await response.json();
      if (data && data.response && data.response.kec) {
        const modifiedKecList = data.response.kec.map((kec) => ({
          kec_id: kec.kec_id,
          kec_name: kec.kec_name,
        }));

        const array = data.response.kec;
        const array_data = array[0]?.kec_id;
        console.log("array pertama:", array_data);
        setKecList(modifiedKecList);
        fetchKelData(array_data);
      }
    } catch (error) {
      console.error('Error fetching kecamatan data:', error);
    }
  };
  const fetchKelData = async (selectedKec) => {
    try {
      const response = await fetch(API.getKel, {
        method: 'POST',
        body: JSON.stringify({ kec_id: selectedKec }),
      });
  
      const data = await response.json();
      if (data && data.response && data.response.kel) {
        const modifiedKelList = data.response.kel.map((kel) => ({
          kel_id: kel.kel_id,
          kel_name: kel.kel_name,
        }));
        setKelList(modifiedKelList);
      }
    } catch (error) {
      console.error('Error fetching kelurahan data:', error);
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) return true;
    else return false;
  };
  const clickListener = async () => {
    if (!user_name || !user_nik|| !user_phone|| !user_email|| !user_tempat_lahir|| !user_address) {
      console.log('Isi semua field sebelum melanjutkan.');
      //alert(`Isi semua field sebelum melanjutkan.`);
      return;
    }
    if (user_nik.length !== 16) {
      console.log('Jumlah NIK harus 16 digit.');
      alert(`Jumlah NIK harus 16 digit.`);
      return;
    }
    user_phone = user_phone.startsWith('+62') ? '0' + user_phone.slice(3) : user_phone;
    if (!validateEmail(user_email)) {
      console.log('Format email tidak valid.');
      alert('Format email tidak valid.');
      return;
    }

    let new_month = parseInt(selectedMonthNumber) + 1;
    let new_day = selectedDay;
    new_day = new_day.toString().padStart(2, '0');
    new_month = new_month.toString().padStart(2, '0');
    const full_moon = `${selectedYear}-${new_month}-${new_day}`;

    console.log('data1: ' + user_name);
    console.log('data2: ' + user_address);
    console.log('data3: ' + user_tempat_lahir);
    console.log('data4: ' + full_moon);
    console.log('data5: ' + selectedGender);
    console.log('data6: ' + user_phone);
    console.log('data7: ' + user_nik);
    console.log('data8: ' + selectedPropId);
    console.log('data9: ' + selectedKabId);
    console.log('data10: ' + selectedKecId);
    console.log('data11: ' + selectedKelId);
    console.log('data12: ' + selectedReligion);
    console.log('data13: ' + selectedPekerjaan);

    try {
      const response = await fetch(API.DaftarPasienSecondary, {
        method: 'POST',
        body: JSON.stringify({
          nama: user_name,
          alamat: user_address,
          tempatlahir: user_tempat_lahir,
          ttl: full_moon,
          jk: selectedGender,
          telp: user_phone,
          ktp: user_nik,
          kdprop: selectedPropId,
          kdkab: selectedKabId,
          kdkec: selectedKecId,
          kdkel: selectedKelId,
          agama: selectedReligion,
          pekerjaan: selectedPekerjaan,
          email_asli: user_email,
        }),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        const code = responseData.metadata.code;
  
        console.log('Data Pasien responseData:', responseData);
        console.log('Data Pasien responseData.metadata:', responseData.metadata);
        console.log('Data Pasien responseData.metadata.message:', responseData.metadata.message);
        console.log('Data Pasien code:', code);
        console.log('Data Pasien code:', responseData.pasien_id);

        if(code === "200") {
          sendOtp({
            nama: responseData.nama,
            pasien_id: responseData.pasien_id,
            nomorHp: responseData.telp,
            address: responseData.alamat,
            noRm: responseData.rm,
          });
        } else alert(`Data tidak ditemukan untuk nomor `);
      } else {
        const errorData = await response.json();
        alert(`Response: ${errorData.metadata.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      console.error('Error:', error.message);
      console.error('Error:', error);
    }
  };
  const onClickListener = () => clickListener();
  async function sendOtp(state) {
    try {
      const response = await fetch(API.postMobileSettingOtpSecondary, {
        method: 'POST',
        body: JSON.stringify({
          pasien_id: state?.pasien_id,
          nomor_hp: state?.nomorHp,
        }),
      });
  
      if (response.status === 200) {
        // Konfirmasi sukses, lakukan apa yang diperlukan, misalnya pindah ke halaman berikutnya
        console.log('pasien_id: ' + state?.pasien_id);
        console.log('nomor_hp: ' + state?.nomorHp);
        console.log('address: ' + state?.address);
        console.log('Konfirmasi OTP berhasil');
          
        Session.setItem("nama", state?.nama);
        Session.setItem("pasien_id", state?.pasien_id);
        Session.setItem("nomorHp", state?.nomorHp);
        Session.setItem("address", state?.address);
        Session.setItem("noRm", state?.noRm);

        console.log('Data Pasien nama:', sessionStorage.getItem('nama'));
        console.log('Data Pasien nomorHp:', sessionStorage.getItem('nomorHp'));
        console.log('Data Pasien noRm:', sessionStorage.getItem('noRm'));
        console.log('Data Pasien pasien_id:', sessionStorage.getItem('pasien_id'));
        console.log('Data Pasien address:', sessionStorage.getItem('address'));
  
        // Pindahkan blok navigate ke sini
        navigate('/form_otp', {
          state: {
            nama: sessionStorage.getItem('nama'),
            nomorHp: sessionStorage.getItem('nomorHp'),
            noRm: sessionStorage.getItem('noRm'),
            pasien_id: sessionStorage.getItem('pasien_id'),
            address: sessionStorage.getItem('address'),
          },
        });
      } else {
        const errorData = await response.json();
        console.error('Konfirmasi OTP gagal:', errorData.metadata.message);
      }
    } catch (error) {
      console.error('Error pada saat mengirim OTP:', error);
    }
  }

    // Variable Declaration CSS
    const TabContent = {minWidth:'200',width: '100%', maxWidth: 300,
      display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop:8};
    const TextInputEditTextStyle = {marginTop: 16,fontSize: 12,minWidth:'200',width: '90%', 
      minHeight: '30px',backgroundColor: colors.white};
    const MaterialSpinner = {fontSize:10, height:40, minWidth:'200',width: '90%', paddingLeft:8, paddingRight:8, borderRadius: 8,
      border: '1px solid #ccc',boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.1)', userSelect: 'none', marginTop:16};
    const MaterialSpinnerItem = {colors:colors.black,fontSize:13, height:30};
    const MaterialSpinnerDate = { flex: 1, border: '1px solid #ccc', borderRadius: 4, height: '40px', fontSize:13};
    const MaterialButtonStyle = {fontSize:14, maxWidth:220,minWidth:'200',width: '96%', height:33, color:colors.white, marginLeft: 'auto', marginRight: 'auto', 
      marginTop:16, backgroundColor: colors.colorPrimary,};
  
  return (
    <div className="Root" style={{paddingTop:8,paddingLeft:4,margin:0,alignContent:'center'}}>
      <AppBarLayout/>
      <main style={{paddingBottom:50,width:'100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h1 style={{fontSize:24, color: colors.colorPrimaryDark, userSelect: 'none', textAlign:'center', marginTop:24 }}>
            Pendaftaran Pasien Baru
          </h1>
            
        {/* TabLayout disini */}
        <TabLayout tabs={[
          // Tab 1 disini
            {id: 'tab1', title: 'Data diri', content: 
            <div style={TabContent}>

                <MaterialEditText1 label="Nama Lengkap"
                  maxLength={50}
                  style={TextInputEditTextStyle}
                  placeholder="Masukkan Nama Lengkap"
                  value={user_name}
                  onChange={(e) => setUserName(e.target.value)}
                />

                <MaterialEditText1 label="Nomor Induk Kependudukan"
                  maxLength={16}
                  style={TextInputEditTextStyle}
                  placeholder="Masukkan NIK"
                  value={user_nik}
                  onChange={(e) => setUserNik(e.target.value)}
                />

                <MaterialEditText1 label="Nomor hp"
                  maxLength={17}
                  style={TextInputEditTextStyle}
                  placeholder="Masukkan Nomor Hp"
                  value={user_phone}
                  onChange={(e) => setUserPhone(e.target.value)}
                />

                <MaterialEditText1 label="Email"
                  maxLength={50}
                  style={TextInputEditTextStyle}
                  placeholder="Masukkan Email"
                  value={user_email}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
            </div> },

          // Tab 2 disini
            { id: 'tab2', title: 'Lanjutan', content:<div style={TabContent}>

            <MaterialEditText1 label="Tempat lahir"
                  maxLength={50}
                  style={TextInputEditTextStyle}
                  placeholder="Masukkan Tempat lahir"
                  value={user_tempat_lahir}
                  onChange={(e) => setUserTempatLahir(e.target.value)}
                />

              <div style={{ marginTop: 16, minWidth: '200', width: '90%', display: 'flex' }}>
                <select style={MaterialSpinnerDate} value={selectedDay}
                  onChange={(e) => setSelectedDay(e.target.value)}>
                  {days.map((day, index) => (
                    <option key={index} value={day}>{day}</option>
                  ))}
                </select>

                <select style={MaterialSpinnerDate} value={selectedMonthNumber}
                  onChange={(e) => setSelectedMonthNumber(e.target.value)}>
                  {ARRAY.months.map((month, index) => (
                    <option key={index} value={month.number}>{month.name}</option>
                  ))}
                </select>

                <select style={MaterialSpinnerDate} value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}>
                  {years.map((year, index) => (
                    <option key={index} value={year}>{year}</option>
                  ))}
                </select>
              </div>

              <select className="SpinnerGender" style={MaterialSpinner} 
                value={selectedGender} 
                onChange={(e) => {
                  setSelectedGender(e.target.value)}}> 
                  {ARRAY.genderList.map((items) => (
                  <option 
                    key={items.code} 
                    value={items.code}> {items.name}
                  </option>
                ))}
              </select>
              <select className="SpinnerAgama" style={MaterialSpinner} 
                value={selectedReligion} 
                onChange={(e) => { setSelectedReligion(e.target.value)}}> 
                  {ARRAY.religionList.map((items) => (
                  <option 
                    key={items.name} 
                    value={items.name}> {items.name}
                  </option>
                ))}
              </select>
              <select className="SpinnerPekerjaan" style={MaterialSpinner} 
                value={selectedPekerjaan} 
                onChange={(e) => setSelectedPekerjaan(e.target.value)}>
                  {pekerjaanList.map((items) => (
                  <option 
                    style={MaterialSpinnerItem} 
                    key={items.pekerjaan_id} 
                    value={items.pekerjaan_name}> {items.pekerjaan_name}
                  </option>
              ))}
              </select>
            </div>},
            
          // Tab 3 disini
            { id: 'tab3', title: 'Alamat', content: <div style={TabContent}>
              <MaterialEditText1 label="Alamat lengkap"
                  maxLength={50}
                  style={TextInputEditTextStyle}
                  placeholder="Masukkan alamat lengka"
                  value={user_address}
                  onChange={(e) => setUserAddress(e.target.value)}
                />

              <select className="SpinnerProp" style={MaterialSpinner} value={selectedProp}
                onChange={(e) => {
                  setSelectedProp(e.target.value);
                  setPropId(e.target.value);
                  fetchKabData(e.target.value);
                }}
              >
              {propList.map((prop) => (
                <option style={MaterialSpinnerItem} key={prop.prop_id} value={prop.prop_id}
                >{prop.prop_name}</option>
              ))}
              </select>
              <select className="SpinnerKab" style={MaterialSpinner} value={selectedKab}
                onChange={(e) => {
                  setSelectedKab(e.target.value);
                  setKabId(e.target.value);
                  fetchKecData(e.target.value);
                }}
              >
              {kabList.map((kab) => (
                <option
                  style={MaterialSpinnerItem}
                  key={kab.kab_id}
                  value={kab.kab_id}
                >
                  {kab.kab_name}
                </option>
              ))}
              </select>
              <select className="SpinnerKec" style={MaterialSpinner} value={selectedKec}
                onChange={(e) => {
                  setSelectedKec(e.target.value);
                  setKecId(e.target.value);
                  fetchKelData(e.target.value);
                }}
              >
              {kecList.map((kec) => (
                <option
                  style={MaterialSpinnerItem}
                  key={kec.kec_id}
                  value={kec.kec_id}
                >
                  {kec.kec_name}
                </option>
              ))}
              </select>
              <select className="SpinnerKel" style={MaterialSpinner} value={selectedKel}
                onChange={(e) => {
                  setSelectedKel(e.target.value);
                  setKelId(e.target.value);
                }}>
                {kelList.map((kel) => (
                  <option style={MaterialSpinnerItem} key={kel.kel_id} value={kel.kel_id}>
                    {kel.kel_name}
                  </option>
                ))}
              </select>
              
            </div>},
        ]} />

        <p style={{ width: '100%', fontSize: 14, color: colors.colorPrimaryDark, userSelect: 'none', textAlign: 'center', marginTop: 16 }}>
                Telah Memiliki Akun?  
                  <Link to="/" style={{ color: 'blue', textDecoration: 'underline' }}>
                    Klik disini
                  </Link> 
                  <br/>untuk masuk
        </p>
        <MaterialButton className="MaterialButton" variant="contained" 
            style={MaterialButtonStyle} 
            onClick={onClickListener}>
            Daftar
        </MaterialButton>
        
          
      </main>
    </div>
  );
}

export default Form_Registration;
