import React, { useState } from 'react';
import './TabLayout.css'; // Import CSS styling for TabLayout

const TabLayout = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const handleTabClick = (tabId) => {setActiveTab(tabId);};

  return (
    <div className="tab-layout-container">
      <div className="tabs-container" style={{userSelect:'none',borderRadius:'8px',minWidth:'200',width: '80%', maxWidth:300, height:40}}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab ${activeTab === tab.id && 'active'}`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.title}
          </div>
        ))}
      </div> 
      <div className="tab-content" style={{userSelect:'none',marginTop:8, minWidth:'200',width: '80%',
       maxWidth:'300px', alignContent:'center', alignItems:'center',display: 'flex', flexDirection: 'column',}}>
        {tabs.find((tab) => tab.id === activeTab)?.content}
      </div>
    </div>
  );
};

export default TabLayout;