import React from 'react';
import Modal from 'react-modal';
import '../GlobalCss.css';
import colors from '../Style_Color/COLOR';
import MaterialButton from '@mui/material/Button';

const half_percentage = '50%';
const ModalDialog = ({isOpen, closeModal, listener, title, text_content, buttonText}) => {
  const modalStyle = {content: {
    height: '170px',
    width: '220px',
      position: 'absolute',
      top: half_percentage,
      left: half_percentage,
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },};
  const ButtonOff = {fontSize: 8, width:60, height:24, color:colors.colorPrimaryDark, backgroundColor:colors.backgroundColor, marginLeft:4};
  const ButtonOn = {fontSize: 8, width:60, height:24, color:colors.white, backgroundColor:colors.colorPrimaryDark, marginLeft:8};
    return (
      <Modal isOpen={isOpen} ariaHideApp={false} style={modalStyle} onRequestClose={closeModal}>
        <div style={{ width: '100%' }}>
          <h2>{title}</h2>
          <p style={{marginBottom:32}}>{text_content}</p>
        </div>

        <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <MaterialButton style={ButtonOff} 
            onClick={closeModal}>Batal</MaterialButton>
          <MaterialButton style={ButtonOn} 
            onClick={listener}>{buttonText === '' ? 'Lanjutkan':buttonText}</MaterialButton>
        </div>
        
      </Modal>
    );
};
const AlertDialog = ({isOpen, closeModal, listener, title, text_content, buttonText}) => {
  const modalStyle = {content: {
      height: '170px',
      width: '220px',
      position: 'absolute',
      top: half_percentage,
      left: half_percentage,
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },};
  const ButtonOff = {fontSize: 8, width:60, height:24, color:colors.colorPrimaryDark, backgroundColor:colors.backgroundColor, marginLeft:4};
  const ButtonOn = {fontSize: 8, width:60, height:24, color:colors.white, backgroundColor:colors.colorPrimaryDark, marginLeft:8};
    return (
      <Modal isOpen={isOpen} ariaHideApp={false} style={modalStyle} onRequestClose={closeModal}>
        <div style={{ width: '100%' }}>
          <h2>{title}</h2>
          <p style={{marginBottom:32}}>{text_content}</p>
        </div>

        <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <MaterialButton style={ButtonOff} 
            onClick={closeModal}>Batal</MaterialButton>
          <MaterialButton style={ButtonOn} 
            onClick={listener}>{buttonText === '' ? 'Lanjutkan':buttonText}</MaterialButton>
        </div>
        
      </Modal>
    );
};

export {ModalDialog, AlertDialog};
