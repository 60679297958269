class Session {
    // Menyimpan data ke sessionStorage
    static setItem(key, value) {
      sessionStorage.setItem(key, '' + value);
    }
    static setItemArray(key, value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
    static setItemToArray(key, newItem) {
        const existingArray = this.getItemArray(key) || [];
        existingArray.push(newItem);
        this.setItemArray(key, existingArray);
      }
  
    // Mendapatkan data dari sessionStorage
    static getItem(key) {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    }
  
    // Menghapus data dari sessionStorage
    static removeItem(key) {
      sessionStorage.removeItem(key);
    }
  
    // Menghapus semua data dari sessionStorage
    static clear() {
      sessionStorage.clear();
    }
  }
  
  export default Session;
  