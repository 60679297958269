import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {createRoot} from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import FORM_OTP from './FORM_OTP/Form_Otp.js';
import FORM_POLI from './FORM_POLI/Form_Poli.js';
import FORM_REGISTRATION from './FORM_REGISTRATION/Form_Registration.js';
import FORM_BUKTI from './FORM_BUKTI/Form_Bukti.js';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/FORM_OTP" element={<FORM_OTP />} />
        <Route path="/FORM_POLI" element={<FORM_POLI />} />
        <Route path="/FORM_REGISTRATION" element={<FORM_REGISTRATION />} />
        <Route path="/FORM_BUKTI" element={<FORM_BUKTI />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
reportWebVitals();
