import React, {  } from 'react';
import '../App.css';
import '../FORM_POLI/Form_Poli.css';
import colors from '../Style_Color/COLOR.js';
import image_compat from '../Style_Image/rsiy_.png';

function AppBarLayout() {

    // Variable Declaration CSS
    const AppBarLayout = {boxShadow: '4px 8px 8px rgba(0, 0, 0, 0.1)', 
    backgroundColor: colors.colorPrimaryDark, userSelect: 'none', 
    paddingLeft: 8, paddingTop:2, paddingBottom:2,
    width: '100vw' };
    
    return (<div className="AppBarLayout" style={AppBarLayout}>
        <img
          className="relative"
          src={image_compat}
          alt="RSIY Logo"
          width={'300'}
          height={64}
          priority="true"
        />
      </div>
    );
  }
  
  
  export default AppBarLayout;