import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import '../App.css';
import MaterialButton from '@mui/material/Button';
import TextInputEditText from '@mui/material/TextField';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import api from '../API.js';
import Session from '../Session.js';
import colors from '../Style_Color/COLOR.js';
import AppBarLayout from '../WIDGET/AppBarLayout.js';
import { ModalDialog } from '../WIDGET/ModalDialog.js';
import ARRAY from '../ARRAY.js';

const today = new Date();
const options_date_1 = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
const options_date_2 = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' };
function setTrueDate(data) {
  return format(data, 'EEEE, dd LLLL yyyy', { locale: id });
}
function getDate(data) {
  return new Date(data);
}
function setTextDate(date){
  return date.toLocaleDateString('id-ID', options_date_1);
}

function Form_Poli() {
  const poliList = ARRAY.poli;
  const [isDataAvaible, setDataAvaible] = useState(false);
  const [selectedPoli, setSelectedPoli] = useState('PO02');
  const [selectedDate, setSelectedDate] = useState(today);
  const [futureDates, setFutureDates] = useState([]);
  const [selectedDokter, setSelectedDokter] = useState('');
  const [selectedKuota, setSelectedKuota] = useState('');
  const [jadwalDokter, setJadwalDokter] = useState([]);
  const [selectedRujukan, setSelectedRujukan] = useState(' ');
  const [selectedPayment, setSelectedPayment] = useState('UMUM');
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  useEffect(() => {
    const generateFutureDates = () => {
        const dates = [];
        for (let i = 0; i < 7; i++) {
            const currentDate = getDate(today);
            currentDate.setDate(today.getDate() + i);
            dates.push(currentDate);
        }
        return dates;
    };
    setFutureDates(generateFutureDates());
  }, []);

  useEffect(() => {
    const fetchDokterData = async () => {
      
      if (selectedPoli && selectedDate) {
        try {
          const response = await fetch(api.getDokterTglSecondary, {
            method: 'POST',
            body: JSON.stringify({
              poli_id: selectedPoli,
              tgl: selectedDate.toISOString().split('T')[0].replace(/-/g, ''),
            }),
          });
          const data = await response.json();
          const Metadata = data.metadata;
          const Response = data.response;
          
          const default_doctor_id = [];
          const default_doctor_name = [];
          const default_time_start = [];
          const default_time_end = [];
          const default_kuota = [];

          if (Metadata && Metadata.code === "200" && Response && Response.Jadwal) {

            Response.Jadwal.forEach((jadwal) => {
              console.log('DR_ID:', jadwal.DR_ID);
              console.log('NMDOKTER:', jadwal.NMDOKTER);
              console.log('TANGGAL:', jadwal.TANGGAL);
              console.log('TIME_START:', jadwal.TIME_START);
              console.log('TIME_END:', jadwal.TIME_END);
              console.log('HARI_ID:', jadwal.HARI_ID);
              console.log('KUOTA:', jadwal.KUOTA);
              default_doctor_id.push(jadwal.DR_ID);
              default_doctor_name.push(jadwal.NMDOKTER);
              default_time_start.push(jadwal.TIME_START.slice(0, -3));
              default_time_end.push(jadwal.TIME_END.slice(0, -3));
              default_kuota.push(jadwal.KUOTA);
            });

            setJadwalDokter(Response.Jadwal);
            setDataAvaible(true);
            setSelectedKuota(default_kuota[0]);
            console.log('Response.Jadwal:', Response.Jadwal);
            console.log('Response.Jadwal:', default_doctor_id[0]);
            console.log('Response.Jadwal:', default_doctor_id[0]-default_time_start[0]-default_time_end[0]);
            setSelectedDokter(`${default_doctor_id[0]}-${default_time_start[0]}-${default_time_end[0]}`);

          } else {
            setDataAvaible(false);
            console.error('Error fetching dokter fetchDokterData1:', Metadata.message);
            setSelectedDokter(`${'false'}-${'00:00'}-${'00:00'}`);
            setSelectedKuota(0);
          }
        } catch (error) {
          console.error('Error fetching dokter fetchDokterData2:', error);
        }
      }
    };

    fetchDokterData();
  }, [selectedPoli, selectedDate]);

  const PostDaftar = async () => {
    try {
      const formattedDate = selectedDate.toLocaleDateString('id-ID', options_date_2);
      const response = await fetch(api.DaftarRJSecondary, {
        method: 'POST',
        body: JSON.stringify({
          dr_id: selectedDokter.split('-')[0],
          pasien_id: state?.pasien_id,
          poli_id: selectedPoli,
          tgl: selectedDate.toLocaleDateString('en-CA'),
          asuransi_id: selectedPayment,
          no_rujukan: selectedRujukan,
        }),
      });

      const data = await response.json();
      const Metadata = data.metadata;
      const Response = data.response;

      if (Metadata && Metadata.code === "200" && Response) {
        let nomorAntrean = Response.nomorantrean;
        if (/^[A-Z]$/.test(nomorAntrean)) nomorAntrean = '1' + nomorAntrean;
        
        console.log('result:', Metadata.message);
        console.log('result Response.nomorantrean:', Response.nomorantrean);
        console.log('result nomorantrean:', nomorAntrean);
        console.log('result:', Response.kodebooking);
        console.log('result:', Response.jenisantrean);
        console.log('result Response.estimasidilayani:', Response.estimasidilayani);  
        console.log('result:', Response.namapoli); 
        console.log('result:', Response.namadokter); 
        
        setModalOpen(false);
        sendNotification({
          user_poli: Response.namapoli,
          user_doctor_name: Response.namadokter,
          user_no_antrian: nomorAntrean,
          user_datetime: formattedDate,
          user_tanggal: setTrueDate(selectedDate),
          user_time_service: Response.estimasidilayani,
          user_jam_praktek: `${selectedDokter.split('-')[1]} - ${selectedDokter.split('-')[2]}`,
          kodebooking: Response.kodebooking,
        });
        
      } else {
        setModalOpen(false);
        console.error('Error fetching dokter PostDaftar:', Metadata.message);
      }
    } catch (error) {
      setModalOpen(false);
      console.error('Error fetching dokter PostDaftar:', error);
    }
  };

  async function sendNotification(state) {
    
    const user_phone = sessionStorage.getItem('nomorHp');
    const user_name = sessionStorage.getItem('nama');
    const user_no_rm = sessionStorage.getItem('noRm');
    try {
      const response = await fetch(api.SendNotificationPendaftaranWeb, {
        method: 'POST',
        body: JSON.stringify({
          user_phone: user_phone,
          user_name: user_name,
          user_no_rm: user_no_rm,
          user_poli: state?.user_poli,
          user_doctor_name: state?.user_doctor_name,
          user_no_antrian: state?.user_no_antrian,
          user_datetime: state?.user_datetime,
          user_time_service: state?.user_jam_praktek
        }),
      });
  
      if (response.status === 200) {

        Session.setItem("user_poli", state?.user_poli);
        Session.setItem("user_doctor_name", state?.user_doctor_name);
        Session.setItem("user_no_antrian", state?.user_no_antrian);
        Session.setItem("user_datetime", state?.user_datetime);
        Session.setItem("user_time_service", state?.user_time_service);
        Session.setItem("asuransi_id", selectedPayment);
        Session.setItem("estimasidilayani", state?.estimasidilayani);
        Session.setItem("kodebooking", state?.kodebooking);
        Session.setItem("user_tanggal", state?.user_tanggal);
        Session.setItem("user_jam_praktek", state?.user_jam_praktek);
          
        console.log('DAMNIT:', state?.user_tanggal);
        console.log('DAMNIT:', state?.user_time_service);
        console.log('DAMNIT:', state?.user_jam_praktek);
        console.log('Data Pasien nama:', sessionStorage.getItem('nama'));
        console.log('Data Pasien nomorHp:', sessionStorage.getItem('nomorHp'));
        console.log('Data Pasien noRm:', sessionStorage.getItem('noRm'));
        console.log('Data Pasien pasien_id:', sessionStorage.getItem('pasien_id'));
        console.log('Data Pasien address:', sessionStorage.getItem('address'));
        console.log('Data Pasien user_poli:', sessionStorage.getItem('user_poli'));
        console.log('Data Pasien user_doctor_name:', sessionStorage.getItem('user_doctor_name'));
        console.log('Data Pasien user_no_antrian:', sessionStorage.getItem('user_no_antrian'));
        console.log('Data Pasien user_datetime:', sessionStorage.getItem('user_datetime'));
        console.log('Data Pasien user_time_service:', sessionStorage.getItem('user_time_service'));
        console.log('Data Pasien asuransi_id:', sessionStorage.getItem('asuransi_id'));
        console.log('Data Pasien estimasidilayani:', sessionStorage.getItem('estimasidilayani'));
        console.log('Data Pasien kodebooking:', sessionStorage.getItem('kodebooking'));
        console.log('Data Pasien user_tanggal:', sessionStorage.getItem('user_tanggal'));
        console.log('Data Pasien user_jam_praktek:', sessionStorage.getItem('user_jam_praktek'));
        navigate('/form_bukti', {
          state: {
            nomorantrean: sessionStorage.getItem('user_no_antrian'),
            kodebooking: sessionStorage.getItem('kodebooking'),
            jenisantrean: Response.jenisantrean,
            estimasidilayani: sessionStorage.getItem('estimasidilayani'),
            namapoli: sessionStorage.getItem('user_poli'),
            namadokter: sessionStorage.getItem('user_doctor_name'),
            pasien_id: sessionStorage.getItem('pasien_id'),
            address: sessionStorage.getItem('address'),
            asuransi_id: sessionStorage.getItem('asuransi_id'),
            user_tanggal: sessionStorage.getItem('user_tanggal'),
            user_jam_praktek: sessionStorage.getItem('user_jam_praktek'),
          },
        });
      } else {
        const errorData = await response.json();
        console.error('Konfirmasi OTP gagal:', errorData.metadata.message);
      }
    } catch (error) {
      console.error('Error pada saat mengirim OTP:', error);
    }
  }

  const onCLickListener = () => {
    PostDaftar();
  };
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {setModalOpen(true);};
  const closeModal = () => {setModalOpen(false);};
  const getPlaceholderText = () => {
    switch (selectedPayment) {
      case 'BPJS': return 'Masukkan No Rujukan';
      case 'ANOTHER': return 'Masukkan No Peserta';
      default: return '';
    }
  };

    // Variable Declaration CSS
    const CardView = {marginLeft:16, marginRight:16,width:'30vw', minWidth:'270px',backgroundColor: colors.white, 
      display: 'flex', flexDirection: 'column',alignItems: 'center',borderRadius: 8, userSelect: 'none', 
      border: '1px solid #ccc',boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)'};
    const CardView2 = {marginLeft:16, marginRight:16, marginTop: 16,width:'30vw', minWidth:'270px',backgroundColor: colors.white, 
      display: 'flex', flexDirection: 'column',alignItems: 'center',borderRadius: 8, userSelect: 'none',paddingBottom: 8, 
      border: '1px solid #ccc',boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)'};
    const TextInputEditTextStyle = {marginTop:12, marginBottom:2,fontSize: 12,width:'calc(100% - 16px)', minWidth:'120px', paddingLeft:8,paddingRight:8,
        textAlign: 'center',borderColor: colors.colorPrimaryDark,alignItems: 'center', height:'45px'};
    const MaterialButtonStyle = {fontSize:14, maxWidth:220,minWidth:'200',width: '96%', height:33, color:colors.white, 
      marginLeft: 'auto', marginRight: 'auto', marginBottom: '16',
      marginTop:16, backgroundColor: colors.colorPrimary,};
    const MaterialSpinner = {fontSize:16, height:40, width:'30vw', minWidth:'270px', paddingLeft:8, paddingRight:8, borderRadius: 8,
        border: '1px solid #ccc',boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)', userSelect: 'none'};
    const MaterialSpinnerItem = {fontSize:14, height:30, width:'30vw', minWidth:'270px', maxWidth:280};
    const TextTitle = {fontSize:22, width:'30vw', minWidth:'270px', color: colors.colorPrimaryDark, userSelect: 'none', textAlign:'center', marginTop:16 };
    const TextTime = {fontSize:12, color: colors.black_light, userSelect: 'none', textAlign:'center'};
    const TextKuota = {fontSize:12, color: colors.black_light, userSelect: 'none', textAlign:'center', marginTop:-8};
    const TextKuotaExtension = {color: selectedKuota === 0 ? colors.red : colors.colorPrimary, fontWeight:'bold'};
    const TextError = {fontSize:12, color: colors.red, userSelect: 'none', textAlign:'center', marginTop:-8,fontWeight: 'bold'};
    const TextPaymentStyle = { fontSize: 18, width: '100%', color: colors.colorPrimaryDark, userSelect: 'none', 
        textAlign: 'center', marginTop:24, marginBottom:8};
  
  return (
    <div className="Root" style={{paddingTop:8,paddingLeft:4,margin:0,alignContent:'center'}}>
      <AppBarLayout/>
      <main style={{paddingBottom:50,width:'100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div className="SpinnerContainer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={TextTitle}>Pilih Poliklinik</h1>

            <select className="Spinner" style={MaterialSpinner}
              value={selectedPoli} onChange={(e) => setSelectedPoli(e.target.value)}>
              {poliList.map((poli) => (
                <option style={MaterialSpinnerItem} key={poli.poli_id} value={poli.poli_id}>
                  {poli.poli_name}
                </option>
              ))}
            </select>

            <h2 style={TextTitle}>Pilih tanggal</h2>
            <select style={MaterialSpinner} 
              value={selectedDate} 
              onChange={(e) => {

                try {
                  const newDate = new Date(e.target.value);
                  !isNaN(newDate.getTime()) ? setSelectedDate(newDate) : console.error('Invalid date format:', e.target.value);
                } catch (error) {
                  console.error('Error parsing date:', error);
                }

              }}>
                  {futureDates.map((date, index) => (
                      <option key={index} value={date}>{`${setTextDate(date)}`}</option>
                  ))}
            </select>

            <h2 style={TextTitle}>Pilih Dokter</h2>
            
            <div style={CardView}>
              <select className="Spinner" 
                style={MaterialSpinner} 
                value={selectedDokter} 
                onChange={(e) => setSelectedDokter(e.target.value)}>
                {isDataAvaible === true && jadwalDokter?.length > 0 ? (
                  jadwalDokter.map((dokter) => (
                    <option key={dokter.DR_ID} 
                      value={`${dokter.DR_ID}-${dokter.TIME_START.slice(0, -3)}-${dokter.TIME_END.slice(0, -3)}`}
                    > {dokter.NMDOKTER === 'false' ? '-':dokter.NMDOKTER}
                    </option>
                  ))
                ) : (
                  <option value={`${'Tidak ada dokter'}-${'00:00'}-${'00:00'}`}>Tidak ada dokter</option>
                )}
              </select>

              <p className="textJamPraktek" style={TextTime}>
                  Jam praktek: {selectedDokter.split('-')[1]} - {selectedDokter.split('-')[2]} WIB
              </p>
              <p className="textKuota" style={TextKuota}>
                  Kuota: <span style={TextKuotaExtension}>{selectedKuota}</span>
              </p>
              <p className="textError" style={TextError}>
              {selectedKuota === 0 ? 'Kuota penuh, silahkan cari hari lain.' : ''}
              </p>

            </div>

            <h2 style={TextTitle}>Pilih cara bayar</h2>
            <select className="Spinner" style={MaterialSpinner} 
              value={selectedPayment} onChange={(e) => setSelectedPayment(e.target.value)}>
              <option value="UMUM" style={MaterialSpinnerItem}>UMUM</option>
              <option value="BPJS" style={MaterialSpinnerItem}>BPJS</option>
              <option value="ANOTHER" style={MaterialSpinnerItem}>ASURANSI SWASTA LAIN</option>
            </select>
 
           

            {selectedPayment !== 'UMUM' && (<> <h2 style={TextPaymentStyle}>{getPlaceholderText()}</h2>
              <div style={CardView2}>
                <TextInputEditText
                                label="Nomor peserta"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                maxLength={17}
                                style={TextInputEditTextStyle}
                                placeholder={getPlaceholderText()}
                                value={selectedRujukan} onChange={(e) => setSelectedRujukan(e.target.value)}
                        />
              </div>
                  </>
            )}

            <div style={{height:50, marginTop:16, width: '100%'}}/>
            <MaterialButton className="MaterialButton" variant="contained" 
              style={MaterialButtonStyle} 
              onClick={isDataAvaible === false ? null:openModal}>
              Kirim
            </MaterialButton>
              
            <div style={{height:100, marginTop:16, width: '100%'}}/>

            <ModalDialog
              isOpen={isModalOpen}
              closeModal={closeModal}
              listener={onCLickListener}
              title="Daftar Poli"
              text_content="Lanjutkan pendaftaran?"
              buttonText={"Lanjutkan"}
            />

        </div>

      </main>

    </div>
  );
}



export default Form_Poli;
