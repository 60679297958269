const ARRAY = {
    genderList: [
        { name: "Laki-laki", code: "1" },
        { name: "Perempuan", code: "2" }
      ], 
    religionList : [
        { name: "ISLAM", code: "AG01" },
        { name: "KATOLIK", code: "AG02" },
        { name: "PROTESTAN", code: "AG03" },
        { name: "BUDHA", code: "AG04" },
        { name: "HINDU", code: "AG05" },
        { name: "ALIRAN KEPERCAYAAN", code: "AG10" },
      ],
    months : [
        { name: "Januari", number: 0 },
        { name: "Februari", number: 1 },
        { name: "Maret", number: 2 },
        { name: "April", number: 3 },
        { name: "Mei", number: 4 },
        { name: "Juni", number: 5 },
        { name: "Juli", number: 6 },
        { name: "Agustus", number: 7 },
        { name: "September", number: 8 },
        { name: "Oktober", number: 9 },
        { name: "November", number: 10 },
        { name: "Desember", number: 11 },
      ], 
    poli : [
        {
          poli_id: "PO02",
          poli_name: "KLINIK ANAK",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "AUDIO",
          poli_name: "KLINIK AUDIOMETRI",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO07",
          poli_name: "KLINIK BEDAH ",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO30",
          poli_name: "KLINIK BEDAH  SYARAF",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "BTKV",
          poli_name: "KLINIK BEDAH TORAKS DAN KARDIO VASKULER",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO32",
          poli_name: "KLINIK EEG",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO05",
          poli_name: "KLINIK GIGI & MULUT",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO25",
          poli_name: "KLINIK JANTUNG DAN PEMBULUH DARAH PAGI",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO20",
          poli_name: "KLINIK JANTUNG DAN PEMBULUH DARAH SORE",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO21",
          poli_name: "KLINIK JIWA",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO06",
          poli_name: "KLINIK KEBIDANAN &  KANDUNGAN",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO11",
          poli_name: "KLINIK KULIT & KELAMIN",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO14",
          poli_name: "KLINIK MATA",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO29",
          poli_name: "KLINIK NYERI",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO08",
          poli_name: "KLINIK ORTHOPEDI",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "sp paru",
          poli_name: "KLINIK PARU",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO03",
          poli_name: "KLINIK PENYAKIT DALAM",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO22",
          poli_name: "KLINIK PSIKOLOGI",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO17",
          poli_name: "KLINIK REHAB.MEDIK",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO09",
          poli_name: "KLINIK SYARAF",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO04",
          poli_name: "KLINIK THT",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO26",
          poli_name: "KLINIK THT PAGI (UMUM)",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO31",
          poli_name: "KLINIK TREADMILL",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO12",
          poli_name: "KLINIK UROLOGI",
    map_bpjs: "_map_bpjs"
  },{
          poli_id: "PO24",
          poli_name: "KLINIK VAKSIN",
    map_bpjs: "_map_bpjs"
  }
      ], 
    PROVINSI : [
      {"prop_id": "PV01", "prop_name": "ACEH"}, 
      {"prop_id": "PV02", "prop_name": "SUMATERA UTARA"},
      {"prop_id": "PV03", "prop_name": "SUMATERA BARAT"},
      {"prop_id": "PV04", "prop_name": "RIAU"},
      {"prop_id": "PV05", "prop_name": "JAMBI"},
      {"prop_id": "PV06", "prop_name": "SUMATERA SELATAN"},
      {"prop_id": "PV07", "prop_name": "BENGKULU"},
      {"prop_id": "PV08", "prop_name": "LAMPUNG"},
      {"prop_id": "PV09", "prop_name": "KEPULAUAN BANGKA BELITUNG"},
      {"prop_id": "PV10", "prop_name": "KEPULAUAN RIAU"},
      {"prop_id": "PV11", "prop_name": "DKI JAKARTA"},
      {"prop_id": "PV12", "prop_name": "JAWA BARAT"},
      {"prop_id": "PV13", "prop_name": "JAWA TENGAH"},
      {"prop_id": "PV14", "prop_name": "DI YOGYA"},
      {"prop_id": "PV15", "prop_name": "JAWA TIMUR"},
      {"prop_id": "PV16", "prop_name": "BANTEN"},
      {"prop_id": "PV17", "prop_name": "BALI"},
      {"prop_id": "PV18", "prop_name": "NUSA TENGGARA BARAT"},
      {"prop_id": "PV19", "prop_name": "NUSA TENGGARA TIMUR"},
      {"prop_id": "PV20", "prop_name": "KALIMANTAN BARAT"},
      {"prop_id": "PV21", "prop_name": "KALIMANTAN TENGAH"},
      {"prop_id": "PV22", "prop_name": "KALIMANTAN SELATAN"},
      {"prop_id": "PV23", "prop_name": "KALIMANTAN TIMUR"},
      {"prop_id": "PV24", "prop_name": "SULAWESI UTARA"},
      {"prop_id": "PV25", "prop_name": "SULAWESI TENGAH"},
      {"prop_id": "PV26", "prop_name": "SULAWESI SELATAN"},
      {"prop_id": "PV27", "prop_name": "SULAWESI TENGGARA"},
      {"prop_id": "PV28", "prop_name": "GORONTALO"},
      {"prop_id": "PV29", "prop_name": "SULAWESI BARAT"},
      {"prop_id": "PV30", "prop_name": "MALUKU"},
      {"prop_id": "PV31", "prop_name": "MALUKU UTARA"},
      {"prop_id": "PV32", "prop_name": "PAPUA"},
      {"prop_id": "PV33", "prop_name": "PAPUA BARAT"}
    ]
    
}

export default ARRAY;