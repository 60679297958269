import React, { useState  } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import '../App.css';
import '../GlobalCss.css';
import '../FORM_POLI/Form_Poli.css';
import Session from '../Session.js';
import MaterialButton from '@mui/material/Button';
import colors from '../Style_Color/COLOR.js';
import AppBarLayout from '../WIDGET/AppBarLayout.js';
import PdfGenerator from '../PdfGenerator.js';
import { ModalDialog } from '../WIDGET/ModalDialog.js';

function Form_Bukti() {
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {setModalOpen(true);};
    const closeModal = () => {setModalOpen(false);};

    const onCLickListener = () => {
      closeModal();
      Session.clear();
      
      console.log('Data Pasien nama:', sessionStorage.getItem('nama'));
      console.log('Data Pasien nomorHp:', sessionStorage.getItem('nomorHp'));
      console.log('Data Pasien noRm:', sessionStorage.getItem('noRm'));
      console.log('Data Pasien pasien_id:', sessionStorage.getItem('pasien_id'));
      console.log('Data Pasien address:', sessionStorage.getItem('address'));
      console.log('Data Pasien user_poli:', sessionStorage.getItem('user_poli'));
      console.log('Data Pasien user_doctor_name:', sessionStorage.getItem('user_doctor_name'));
      console.log('Data Pasien user_no_antrian:', sessionStorage.getItem('user_no_antrian'));
      console.log('Data Pasien user_datetime:', sessionStorage.getItem('user_datetime'));
      console.log('Data Pasien user_time_service:', sessionStorage.getItem('user_time_service'));
      console.log('Data Pasien asuransi_id:', sessionStorage.getItem('asuransi_id'));
      console.log('Data Pasien estimasidilayani:', sessionStorage.getItem('estimasidilayani'));
      console.log('Data Pasien kodebooking:', sessionStorage.getItem('kodebooking'));
      console.log('Data Pasien user_tanggal:', sessionStorage.getItem('user_tanggal'));
      console.log('Data Pasien user_jam_praktek:', sessionStorage.getItem('user_jam_praktek'));
      navigate('/');
    };

    const onClickLeftListener = () => {
      PdfGenerator(state);

      openModal(true);
    };

    const onClickRightListener = () => {
      Session.clear();
      
      console.log('Data Pasien nama:', sessionStorage.getItem('nama'));
      console.log('Data Pasien nomorHp:', sessionStorage.getItem('nomorHp'));
      console.log('Data Pasien noRm:', sessionStorage.getItem('noRm'));
      console.log('Data Pasien pasien_id:', sessionStorage.getItem('pasien_id'));
      console.log('Data Pasien address:', sessionStorage.getItem('address'));
      console.log('Data Pasien user_poli:', sessionStorage.getItem('user_poli'));
      console.log('Data Pasien user_doctor_name:', sessionStorage.getItem('user_doctor_name'));
      console.log('Data Pasien user_no_antrian:', sessionStorage.getItem('user_no_antrian'));
      console.log('Data Pasien user_datetime:', sessionStorage.getItem('user_datetime'));
      console.log('Data Pasien user_time_service:', sessionStorage.getItem('user_time_service'));
      console.log('Data Pasien asuransi_id:', sessionStorage.getItem('asuransi_id'));
      console.log('Data Pasien estimasidilayani:', sessionStorage.getItem('estimasidilayani'));
      console.log('Data Pasien kodebooking:', sessionStorage.getItem('kodebooking'));
      console.log('Data Pasien user_tanggal:', sessionStorage.getItem('user_tanggal'));
      console.log('Data Pasien user_jam_praktek:', sessionStorage.getItem('user_jam_praktek'));
      navigate('/');
    };

  // Variable Declaration CSS
  const CardView = {marginLeft:16, marginRight:16,width:'30vw', minWidth:'270px',backgroundColor: colors.white, 
    display: 'flex', flexDirection: 'column',alignItems: 'center',borderRadius: 8, userSelect: 'none', 
    border: '1px solid #DDD',boxShadow: '4px 8px 8px rgba(0, 0, 0, 0.1)'};
  const CardViewInside = {paddingTop:16, marginBottom:16,marginLeft:16, marginRight:12,width:'27vw', 
    minWidth:'250px',backgroundColor: colors.test, display: 'flex', flexDirection: 'column',
    alignItems: 'center',borderRadius: 8, userSelect: 'none', 
    border: '1px solid #DDE',boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.1)'};
  const TextTitle = {fontSize:22, width:'30vw', minWidth:'270px', color: colors.colorPrimaryDark, userSelect: 'none', textAlign:'center', marginTop:16 };
  const TextSmall = {fontSize:12, width:'30vw', minWidth:'270px', color: colors.black_light, userSelect: 'none', textAlign:'center', marginTop:16 };
  const TextMedium = {fontSize:17, width:'30vw', minWidth:'270px', color: colors.colorPrimaryDark, userSelect: 'none', textAlign:'center', marginTop:-8 };
  const TextContent = {fontSize:12, width:'100%', minWidth:'250px', color: colors.black_light,
   userSelect: 'none', textAlign:'left', marginTop:-8, marginLeft:16 };
  const ButtonRight = {fontSize: 8, width:60, height:24, color:colors.white, backgroundColor:colors.colorPrimaryDark, marginLeft:4};
  const ButtonLeft = {fontSize: 8, width:60, height:24, color:colors.colorPrimaryDark, backgroundColor:colors.white, marginRight:4};

  return (
    <div className="Root" style={{paddingTop:8,paddingLeft:4,margin:0,alignContent:'center'}}>
      <AppBarLayout/>
      <main style={{paddingBottom:50,width:'100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div className="SpinnerContainer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={TextTitle}>Proses booking berhasil &#10004;</h1>

          <div style={CardView}>
            <p style={TextSmall}>Kode booking anda:</p>
            <p style={TextMedium}>{sessionStorage.getItem('kodebooking')}</p>
            <h1 style={TextMedium}>{sessionStorage.getItem('user_no_antrian')}</h1>
            <p style={TextSmall}>Informasi detail:</p>
            <div style={CardViewInside}>
                <p style={TextContent}>{'Nama: ' + sessionStorage.getItem('nama')}</p>
                <p style={TextContent}>{'Alamat: ' + sessionStorage.getItem('address')}</p>
                <p style={TextContent}>{'Asuransi: ' + sessionStorage.getItem('asuransi_id')}</p>
                <p style={TextContent}>{`Estimasi dilayani: pukul ${sessionStorage.getItem('user_jam_praktek')} WIB`}</p>
                <p style={TextContent}>{sessionStorage.getItem('kodebooking')}</p>

                <div style={{marginTop:16, display: 'flex', justfyContent: 'space-between', paddingBottom:8 }}>
                    <MaterialButton className="ButtonLeft" variant="contained" 
                      style={ButtonLeft} 
                      onClick={onClickLeftListener}>
                      Download PDF
                    </MaterialButton>
                    <MaterialButton className="ButtonRight" variant="contained" 
                      style={ButtonRight} 
                      onClick={onClickRightListener}>
                      Lanjutkan
                    </MaterialButton>
                </div>

            </div>
          </div>  
          <canvas style={{width:0, height:0}} id="PdfGenerator"></canvas>

          <ModalDialog
              isOpen={isModalOpen}
              closeModal={closeModal}
              listener={onCLickListener}
              title="Pengunduhan Selesai"
              text_content="Pengunduhan bukti pendaftaran telah selesai. Lanjutkan?"
              buttonText={"Lanjutkan"}
            />

        </div>
      </main>

    </div>
  );
}



export default Form_Bukti;
