const ApiExtension = 'https://mobile.rsiypdhi.com/ApiExtension/';
const ws = 'https://sdmws.rsiypdhi.com/ws/';
const api = {
    getDataPasienSecondary: ApiExtension + "getDataPasienSecondary",
    postMobileSettingOtpSecondary: ApiExtension + "postMobileSettingOtpSecondary",
    checkOTPSecondary: ApiExtension + "checkOTPSecondary",
    getPoliSecondary: ApiExtension + "getPoliSecondary",
    getDokterTglSecondary: ApiExtension + "getDokterTglSecondary",
    DaftarRJSecondary: ApiExtension + "DaftarRJSecondary",
    DaftarPasienSecondary: ApiExtension + "DaftarPasienSecondary",
    getProp: ApiExtension + "getProp",
    getKab: ApiExtension + "getKab",
    getKec: ApiExtension + "getKec",
    getKel: ApiExtension + "getKel",
    getPekerjaan: ApiExtension + "getPekerjaan",
    SendNotificationPendaftaranWeb: ws + "SendNotificationPendaftaranWeb",
  };
  
  export default api;