import jsPDF from 'jspdf';
import image_compat from './Style_Image/image_rsiy_logo.jpg';
import JsBarcode from 'jsbarcode';
import colors from './Style_Color/COLOR.js';

const pdf = new jsPDF('p', 'mm', 'a6');

function Objects(text) {
    if(text === null) return 'replacement';
    else if(text === 'null') return 'replacement';
    else if(text === '') return 'replacement';
    else if(text === undefined) return 'replacement';
    else return text;
}

function getText(text) {
    const textValue = typeof text === 'string' || typeof text === 'number' ? text : '';
    console.log('BarcodeGenerator text: ' + text);
    console.log('BarcodeGenerator textValue: ' + textValue);
    const textWidth = pdf.getTextDimensions(textValue).w;
    return (pdf.internal.pageSize.width - textWidth) / 2;
}

function setText(text, heightPosition, colorResource) {
    pdf.setTextColor(colorResource);
    return pdf.text(text, getText(text), heightPosition);
}

function setFont(font, fontSize) {
  pdf.setFontSize(fontSize);
  pdf.setFont(font);
}

const setBarcode = (barcodeValue, canvasId) => {
    JsBarcode(`#${canvasId}`, barcodeValue, {
      format: 'CODE128',
      displayValue: false,
      fontSize: 12,
    });
    return document.getElementById(canvasId);
  };

  function getIntent(item_name){
    return sessionStorage.getItem(item_name);
  }

const PdfGenerator = (state) => {
  const pageHeight = pdf.internal.pageSize.height;
  const pageWidth = pdf.internal.pageSize.width;
  pdf.setFillColor(colors.white);
  //pdf.rect(0, 0, pageWidth, 25, 'F');
  pdf.setFillColor(colors.backgroundColor);
  pdf.rect(0, 26, pageWidth, pageHeight-26, 'F');
//   pdf.setFillColor(colors.grey);
//   pdf.rect(0, contentTop, pageWidth, contentHeight, 'F');
  const RSIY_NAME = 'RS ISLAM YOGYAKARTA PDHI';
  const RSIY_ADDRESS = 'Jln. Solo Km. 12,5 Kalasan Sleman Yogyakarta';
  const RSIY_TELEPHONE = 'Telp. (0274) 498000';
  
          
  console.log('Data Pasien nama:', sessionStorage.getItem('nama'));
  console.log('Data Pasien nomorHp:', sessionStorage.getItem('nomorHp'));
  console.log('Data Pasien noRm:', sessionStorage.getItem('noRm'));
  console.log('Data Pasien pasien_id:', sessionStorage.getItem('pasien_id'));
  console.log('Data Pasien address:', sessionStorage.getItem('address'));
  console.log('Data Pasien user_poli:', sessionStorage.getItem('user_poli'));
  console.log('Data Pasien user_doctor_name:', sessionStorage.getItem('user_doctor_name'));
  console.log('Data Pasien user_no_antrian:', sessionStorage.getItem('user_no_antrian'));
  console.log('Data Pasien user_datetime:', sessionStorage.getItem('user_datetime'));
  console.log('Data Pasien user_time_service:', sessionStorage.getItem('user_time_service'));
  console.log('Data Pasien asuransi_id:', sessionStorage.getItem('asuransi_id'));
  console.log('Data Pasien estimasidilayani:', sessionStorage.getItem('estimasidilayani'));
  console.log('Data Pasien kodebooking:', sessionStorage.getItem('kodebooking'));
  console.log('Data Pasien user_tanggal:', sessionStorage.getItem('user_tanggal'));

  pdf.addImage(image_compat, 'jpg', 0, 0, 25, 25);
  setFont('helvetica', 12);
  setText(RSIY_NAME, 8, colors.black);

  setFont('normal', 8);
  setText(RSIY_ADDRESS, 13, colors.black_light);
  setText(RSIY_TELEPHONE, 16, colors.black_light);
  
  pdf.line(0, 26, pageWidth, 26);

  setFont('helvetica', 14);
  setText('Kode Booking:', 35, colors.black);

  const canvasId = 'PdfGenerator';
  const text_code = Objects(sessionStorage.getItem('kodebooking') || 'text_code');
  const generatedCanvas = setBarcode(text_code, canvasId);
  const canvasSecondary = document.getElementById(canvasId);

  if (canvasSecondary) {
    const barcodeWidth = 40;
    const barcodeX = (pageWidth - barcodeWidth) / 2;
    pdf.addImage(generatedCanvas.toDataURL('image/png'), 'PNG', barcodeX, 37, barcodeWidth, 10);
  } else console.error('Elemen canvas tidak ditemukan.');
  
  setFont('normal', 12);
  setText(text_code, 52, colors.black_light);

  pdf.setFontSize(16);
  setText(`` + Objects(sessionStorage.getItem('nama') || 'text_patient_name'), 63, colors.black);
  
  setFont('normal', 10);
  setText(`(${Objects(sessionStorage.getItem('noRm') || 'text_no_rm')})`, 68, colors.black_light);

  setFont('normal', 12);
  setText('Nomor Antrian:', 78, colors.black_light);

  setFont('helvetica', 16);
  setText(`` + Objects(sessionStorage.getItem('user_no_antrian') || ''), 84, colors.black);

  setFont('normal', 12);
  const text_doctor_name = Objects(getIntent('user_doctor_name') || 'text_doctor_name');
  const text_poli_name = Objects(getIntent('user_poli') || 'text_poli_name');
  setText(`${text_doctor_name} - ${text_poli_name}`, 90, colors.black_light);
  
  setText(`` + Objects(getIntent('user_tanggal') || 'text_date'), 95, colors.black_light);
  console.log('Data Pasien user_poli:', sessionStorage.getItem('user_poli'));
  console.log('Data Pasien user_doctor_name:', sessionStorage.getItem('user_doctor_name'));

  setFont('normal', 10);
  setText(`Mohon hadir pada jam ` + Objects(getIntent('user_jam_praktek') || 'text_time') + ` WIB.`, 115, colors.black_light);
  setText(`Silahkan Saat datang langsung menuju ke Anjungan Mandiri`, 120, colors.black_light);
  setText(`(berada disebelah barat pendaftaran)`, 125, colors.black_light);
  setText(`ketikkan Nomor RM anda kemudian Klik/Tekan Enter.`, 130, colors.black_light);
  setText(`Atau dapat juga dengan scan barcode anda`, 135, colors.black_light);
  setText(`dengan Kode barcode pendaftaran diatas.`, 140, colors.black_light);

  pdf.save(`bukti_pendaftaran_${Objects(sessionStorage.getItem('nama') || 'text_patient_name')}.pdf`);
  
};

export default PdfGenerator;